import React, { Fragment } from 'react';
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { Search, FilterList } from '@material-ui/icons';
import { Fallback, PopperFilter, SortingLabel } from 'components';
import useStyles from './styles';

export default function TableComponent({ type, columns, rows, loading, pagination, handleParams, atualizePopperfilter }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = React.useState('');

  const handleClick = newFilter => event => {
    setAnchorEl(event.currentTarget);
    setOpen(prev => filter !== newFilter || !prev);
    setFilter(newFilter);
  };

  const handleChangePage = (_event, newPage) => {
    handleParams({ limit: pagination.limitPerPage, page: newPage + 1 });
  };

  const handleChangeRowsPerPage = event => {
    handleParams({ limit: +event.target.value, page: pagination.currentPage });
  };

  const Loading = () => (
    <Box className={classes.loader} width={1} p={2}>
      <CircularProgress size={16} />
      <Box component="span" ml={2}>
        Loading...
      </Box>
    </Box>
  );

  if (!rows) {
    return <Fallback />;
  }

  if (type) {
    return (
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, i) => (
                  <Fragment key={i}>
                    <TableCell className={classes.headCell} align={column.align} style={{ minWidth: column.minWidth }}>
                      <p className={classes.cellHeader}>{column.label}</p>
                    </TableCell>
                  </Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow hover tabIndex={0} key={i}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell className={classes.variant} key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <Fragment key={i}>
                  <TableCell className={classes.headCell} align={column.align} style={{ minWidth: column.minWidth }}>
                    <div className={classes.cellHeader}>
                      {column.hasSorting && (
                        <SortingLabel
                          active={column.hasSorting}
                          column={column.id}
                          direction={column.direction}
                          onClick={column.handleSort}
                        />
                      )}
                      <p
                        className={classes.cellHeader}
                        style={{
                          cursor: !!column.filter ? 'pointer' : 'default',
                          marginLeft: column.hasSorting ? '.8rem' : 0,
                        }}
                        onClick={!!column.filter ? handleClick(column.label) : undefined}>
                        {column.label} {column.filter === 'search' && <Search />}
                        {column.filter === 'date' && <Search />}
                        {column.filter === 'check' && <FilterList />}
                      </p>
                    </div>

                    <PopperFilter
                      width={column.width}
                      selected={filter}
                      label={column.label}
                      variant={column.filter}
                      open={open}
                      anchorEl={anchorEl}
                      handleClick={handleClick}
                      onChange={column.onSearch}
                      searchParam={column.id}
                      filterLabels={column.filterLabels}
                      atualizePopperfilter={atualizePopperfilter}
                    />
                  </TableCell>
                </Fragment>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <tbody>
              <tr>
                <td className={classes.loadingContainer}>
                  <Loading />
                </td>
              </tr>
            </tbody>
          ) : (
            <TableBody>
              {rows.map((row, i) => {
                return (
                  <TableRow hover tabIndex={0} key={i}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <TableCell className={classes.bodyCell} key={column.id} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {rows.length === 0 && <p className={classes.noResults}>No results found</p>}
      </TableContainer>

      {Boolean(pagination) && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={pagination?.totalItems ?? -1}
          rowsPerPage={pagination?.limitPerPage ?? 30}
          page={pagination?.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
