import React from 'react';
import { Box, Radio, RadioGroup, Popper, Fade, FormGroup, FormControlLabel, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useStyles from './styles';
import enLocale from 'date-fns/locale/en-US';

export default function PopperFilter({
  open,
  anchorEl,
  label,
  selected,
  variant,
  width,
  onChange,
  searchParam,
  filterLabels,
  atualizePopperfilter,
}) {
  const classes = useStyles();
  const [searchVal, setSearchVal] = React.useState('');
  const [checkbox, setCheckbox] = React.useState('');
  const [resetTrigger, setResetTrigger] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleOptions = e => {
    setCheckbox(e.target.value);
  };

  const handleChange = e => {
    setSearchVal(e.target.value);
  };

  const handleSearch = () => {
    return variant === 'check'
      ? onChange(checkbox, searchParam)
      : variant === 'date'
      ? onChange(selectedDate, searchParam)
      : onChange(searchVal, searchParam);
  };

  const handleEnter = e => {
    return e.charCode === 13 ? handleSearch() : null;
  };

  const handleReset = () => {
    if (variant === 'check') {
      setCheckbox('');
      onChange('');
    } else {
      setSearchVal('');
      onChange('', searchParam);
    }
  };

  React.useEffect(() => {
    if (atualizePopperfilter) {
      setResetTrigger(true);
    }
  }, [atualizePopperfilter]);

  React.useEffect(() => {
    if (resetTrigger) {
      setCheckbox('');
      setResetTrigger(false);
    }
  }, [resetTrigger]);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  if (selected === label) {
    return (
      <Popper style={{ zIndex: 2 }} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              className={classes.filterBox}
              sx={{
                maxWidth: width,
                p: 1,
                bgcolor: 'background.paper',
              }}>
              {variant === 'check' ? (
                <FormGroup className={classes.formGroup}>
                  <RadioGroup className={classes.radioGroup} aria-label="options" value={checkbox} onChange={handleOptions}>
                    {filterLabels &&
                      filterLabels.map(opt => (
                        <FormControlLabel
                          key={opt.toLowerCase()}
                          control={<Radio />}
                          label={opt}
                          value={opt.toLowerCase()}
                        />
                      ))}
                  </RadioGroup>
                </FormGroup>
              ) : variant === 'date' ? (
                <Box mb={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enLocale}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Select Date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              ) : (
                <Box mb={4}>
                  <TextField
                    className={classes.customTextField}
                    id="filter-popper"
                    placeholder="Search here..."
                    variant="outlined"
                    size="small"
                    value={searchVal}
                    onChange={handleChange}
                    onKeyPress={handleEnter}
                  />
                </Box>
              )}

              <div className={classes.wrapper}>
                <Button onClick={handleReset} className={classes.underlinedButton}>
                  Reset
                </Button>
                <Button
                  className={classes.submitButton}
                  onClick={handleSearch}
                  color="primary"
                  variant="contained"
                  style={
                    variant === 'check'
                      ? {
                          maxWidth: '40px',
                          maxHeight: '30px',
                          minWidth: '40px',
                          minHeight: '30px',
                        }
                      : null
                  }>
                  {variant !== 'check' ? 'Search' : 'Ok'}
                </Button>
              </div>
            </Box>
          </Fade>
        )}
      </Popper>
    );
  } else {
    return null;
  }
}
